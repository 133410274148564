import { loading } from './modules/loading';
import { headerAction } from './modules/headerAction';
import { infoSlide } from './modules/infoSlide';
import { mouseStalker } from './modules/mouseStalker';
import { lodashAction } from './modules/lodashAction';
import { scrollmagic } from './modules/scrollmagic';
import { anchorSmooth } from './modules/anchorSmooth';
import { btnJudge } from './modules/btnJudge';
import { sectionScroll } from './modules/sectionScroll';
import { acAction } from './modules/acAction';
import { fileCheck } from './modules/fileCheck';
anchorSmooth();
loading();
headerAction();
mouseStalker();
scrollmagic();
infoSlide();
lodashAction();
btnJudge();
sectionScroll();
acAction();
fileCheck();
